<script setup lang="ts">
import type { Schemas } from "#shopware";

const props = defineProps<{
  navigationId: string;
}>();

const { search } = useCategorySearch();
const route = useRoute();

const { data: categoryResponse } = await useAsyncData(
  "cmsNavigation" + props.navigationId,
  async () => {
    const category = await search(props.navigationId, {
      withCmsAssociations: true,
      query: {
        ...route.query,
      },
    });
    return category;
  },
);
if (categoryResponse.value) {
  const breadcrumbs = getCategoryBreadcrumbs(categoryResponse.value, {
    startIndex: 1,
  });
  useBreadcrumbs(breadcrumbs);
}

const { category } = useCategory(categoryResponse as Ref<Schemas["Category"]>);
useCmsHead(category, { mainShopTitle: "Scheppach Shop" });
</script>

<template>
  <LayoutBreadcrumbs v-if="route.path != '/'" />
  <CmsPage
    v-if="category?.cmsPage"
    :content="category.cmsPage as unknown as Schemas['CmsPage']"
  />

  <ProductCompareFloatingButton />
</template>
